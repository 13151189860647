import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {metrics} from 'react-metrics';
import {connect} from 'react-redux';
import {loadTranslations} from 'react-redux-i18n';
import {withRouter} from 'react-router';
// import {branding, GiftingHeader, GiftingSpinner, translationsObject} from 'trp-components';
// import {branding, GiftingHeader, GiftingSpinner, translationsObject} from '@trp-md529-gifting/trpgifting529';
import shared from '@trp-md529-gifting/trpgifting529';
import {fetchAemData} from '../../store/actions/aem/aem.action';
import {fetchUser, storeUserId} from '../../store/actions/user/user.action';
import Footer from '../footer/footer.container';
import config from './analytics.config';

import './main.scss';
import {addBankingAuthentication} from "../../store/actions/banking/authentication.action";
const { atoms: { GiftingSpinner }, molecules: { GiftingHeader }, services: { branding, translationsObject } } = shared;

export const mapStateToProps = (state, ownProps) => {
  const splitString = ownProps.location.pathname.split('/');
  let id = splitString[splitString.length - 1];
  if (id.includes('oauth')) {
    id = window.localStorage.getItem('giftAccountID');

  }
  return {
    user: state.user,
    id: id,
    brand: get(state.user, 'brand'),
    urlBrand: get(ownProps, 'match.params.brand'),
    aem: state.aem,
    routing: state.routing
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id, urlBrand) => {
      dispatch(fetchUser(id, urlBrand));
    },
    fetchAemData: (brand) => {
      dispatch(fetchAemData(brand));
    },
    storeUserId: (id) => {
      dispatch(storeUserId({id}));
    },
    applyBrandedTranslations: (brand) => {
      const brandedTranslations = merge(cloneDeep(translationsObject), branding[brand]);
      dispatch(loadTranslations(brandedTranslations));
    },
    onAddBankingAuthentication: (payload) => {
      dispatch(addBankingAuthentication(payload));
    }
  };
};

class Main extends Component {

  componentWillMount() {
    this.hasAccessToPage();
    this.props.fetchUser(this.props.id, this.props.urlBrand);
    this.props.storeUserId(this.props.id);
    if (window.location.pathname.includes('oauth')) {
      this.props.onAddBankingAuthentication(window.localStorage.getItem('authId'));
      window.localStorage.removeItem('authId');
    }
    this.setState({
      isDataAvailable: false,
      isCheckPermissions: true
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.brand) && isEmpty(this.props.brand)) {
      this.props.applyBrandedTranslations(nextProps.brand);
      this.props.fetchAemData(nextProps.brand);
    }
    this.isDataAvailable(nextProps);
  }

  hasAccessToPage(){
    if (this.isProductionMode())
      return;
    if (this.isIdMissingFromURL()) {
      this.redirect('failed/not-found', 'replace');
    }
    if(!this.isHomePage()) {
      this.redirect(this.props.id, 'replace');
    }
  }

  isProductionMode(){
    return process.env.NODE_ENV !== 'production';
  }

  isIdMissingFromURL(){
    return isEmpty(this.props.id);
  }

  isHomePage() {
    return ((!isEmpty(get(this.props,'router.routes.1')) &&
      this.props.router.routes[1].path === ':id')) ||
      this.props.history.location.pathname.includes('oauth');
  }

  isDataAvailable(props) {
    if ( !isEmpty(get(props, 'user'))
      && !isEmpty(get(props, 'location'))
      && !isEmpty(get(props, 'aem'))) {
      this.setState({
        isDataAvailable: true
      });
    }
  }

  redirect(url, method) {
    if (method === 'back') {
      this.props.history.goBack();
    } else if (url && method) {
      this.props.history[method](url);
    }
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        redirect: this.redirect.bind(this),
        urlBrand: this.props.urlBrand
      })
    );
    const showSpinner = () => (!this.state.isDataAvailable);

    return (
      <div id="main" className={this.props.brand}>
        {
          this.state.isDataAvailable &&
          <div>
            {!window.location.pathname.includes('oauth') && <GiftingHeader/>}
            <div className="container gifting-main">
              {childrenWithProps}
            </div>
            {!window.location.pathname.includes('oauth') && <Footer/>}
          </div>
        }
        {
          showSpinner() &&
          <GiftingSpinner/>
        }
      </div>
    );
  }
}

Main.propTypes = {
  children: PropTypes.element,
  fetchAemData: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchBanks: PropTypes.func,
  storeUserId: PropTypes.func,
  brand: PropTypes.string,
  user: PropTypes.object,
  location: PropTypes.object,
  id: PropTypes.string,
  applyBrandedTranslations: PropTypes.func,
  aem: PropTypes.object,
  router: PropTypes.object,
  urlBrand: PropTypes.string
};

export default withRouter(metrics(config)(connect(mapStateToProps, mapDispatchToProps)(Main)));
export {Main as Pure};
