import find from 'lodash/find';
import {createAction} from 'redux-actions';

export const Actions = {
  FETCH_COMPANY_CONTACT_SUCCESS: 'FETCH_COMPANY_CONTACT_SUCCESS',
  FETCH_COMPANY_CONTACT_ERROR: 'FETCH_COMPANY_CONTACT_ERROR',
};

export const fetchCompanyContactSuccess = createAction(Actions.FETCH_COMPANY_CONTACT_SUCCESS);
export const fetchCompanyContactError = createAction(Actions.FETCH_COMPANY_CONTACT_ERROR);

export const contactData =  [
  {
    "name": "maryland-529", // todo: is that the key?
    "defaultMessage": "string",
    "phoneNumber": {
      "areaCode": "888",
      "exchange": "888-4MD-GRAD", // todo: what is that?
      "localNumber": "463-4723"
    },
    "hoursOfOperations": [
      {
        "dayOfWeekRange": {
          "endDay": "Friday",
          "startDay": "Monday"
        },
        "timeRange": {
          "minimum": "2017-05-25T09:30:00.823Z",
          "maximum": "2017-05-25T20:45:00.824Z"
        }
      }
    ]
  },
  {
    "name": "alaska-529", // todo: is that the key?
    "defaultMessage": "string",
    "phoneNumber": {
      "areaCode": "888",
      "exchange": "888-4MD-GRAD", // todo: what is that?
      "localNumber": "463-4723"
    },
    "hoursOfOperations": [
      {
        "dayOfWeekRange": {
          "endDay": "Friday",
          "startDay": "Monday"
        },
        "timeRange": {
          "minimum": "2017-05-25T09:30:00.823Z",
          "maximum": "2017-05-25T20:45:00.824Z"
        }
      }
    ]
  },
  {
    "name": "national-529", // todo: is that the key?
    "defaultMessage": "string",
    "phoneNumber": {
      "areaCode": "888",
      "exchange": "888-4MD-GRAD", // todo: what is that?
      "localNumber": "463-4723"
    },
    "hoursOfOperations": [
      {
        "dayOfWeekRange": {
          "endDay": "Friday",
          "startDay": "Monday"
        },
        "timeRange": {
          "minimum": "2017-05-25T09:30:00.823Z",
          "maximum": "2017-05-25T20:45:00.824Z"
        }
      }
    ]
  }
];

export function fetchCompanyContact(brand) {
  const companyContactInfo = find(contactData, {name: brand});
  return dispatch =>
  {
    return dispatch(fetchCompanyContactSuccess(companyContactInfo || {}));
  };
}
