import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {giftAmountChanged} from '../../store/actions/gift-amount/gift-amount.action';

import {CONSTANTS} from "../../constants/gifter-app.constants";
import {AmountComponent} from './amount.component';

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    aem: state.aem,
    giftAmount: state.giftAmount,
    bankingAccountOwner: state.bankingAccountOwner
  };
};

class AmountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonEnabled: !!this.props.giftAmount && this.props.giftAmount >= CONSTANTS.giftAmount.min && this.props.giftAmount <= CONSTANTS.giftAmount.max
    };
  }
  componentWillMount() {
    if (isEmpty(this.props.bankingAccountOwner)) {
      this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/select-owner/${this.props.user.giftAccountGUID}`);
    }
    this.onAmountInputChange = this.onAmountInputChange.bind(this);
  }

  onAmountInputChange(val = '', validation = []) {
    let isButtonEnabled = !!val && !validation.length;

    val = val.replace(/,/g, '');

    this.setState({
      isButtonEnabled
    });

    this.props.onAmountInputChange(parseInt(val, 10));
  }

  redirect() {
    this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/email/${this.props.user.giftAccountGUID}`);
  }

  render() {
    return (
      <AmountComponent
        onAmountInputChange={this.onAmountInputChange.bind(this)}
        redirect={this.redirect.bind(this)}
        giftAmount={this.props.giftAmount}
        aem={this.props.aem}
        isButtonEnabled={get(this.state, 'isButtonEnabled')}
        minAmount={CONSTANTS.giftAmount.min}
        maxAmount={CONSTANTS.giftAmount.max}
      />
    );
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    onAmountInputChange: (newAmount) => {
      dispatch(giftAmountChanged(newAmount));
    }
  };
};

AmountContainer.propTypes = {
  user: PropTypes.object,
  bankingAccountOwner: PropTypes.string,
  params: PropTypes.object,
  aem: PropTypes.object,
  onAmountInputChange: PropTypes.func,
  giftAmount: PropTypes.number,
  redirect: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AmountContainer);
export {AmountContainer};
