import {actions} from '../../actions/gift-amount/gift-amount.action';

export default function giftAmountReducer(state = null,
                                          action = {}) {
  switch (action.type) {
    case actions.GIFT_AMOUNT_CHANGED:
      return action.payload;
    default:
      return state;
  }
}
