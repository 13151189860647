import { Actions } from '../../actions/banking/accounts-action';

export default function accountsReducer(state = {}, action = {}) {
  switch (action.type) {
    case Actions.BANKING_ACCOUNTS_RETRIEVED:
      return {
        accounts: action.payload
      };
    case Actions.CLEAR_BANKING_ACCOUNTS:
      return {};
    default:
      return state;
  }
}
