import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Translate} from 'react-redux-i18n';
// import {BeneficiaryProfilePicture} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';
import './failed-request.scss';
const { atoms: { BeneficiaryProfilePicture } } = shared;

class FailedRequest extends Component {
  constructor() {
    super();
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (<div className="failed-request text-center">
      <BeneficiaryProfilePicture firstName={''} pictureSrc={''}/>

      <div className="failed-request__illustration"/>

      <div className="v-space-40"/>

      <div className="row">
        <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
          <h2>
            <Translate value="gifter.failure500.title"/>
          </h2>

          <div className="v-space-20"/>

          <p><Translate value="gifter.failure500.message"/></p>

          <div className="v-space-20"/>

          <button className="btn btn-default btn-block"
                 onClick={() => this.goBack()}>
            <Translate value="gifter.failure500.btnLabel"/>
          </button>
        </div>
      </div>
    </div>);
  }
}

FailedRequest.propTypes = {
  redirect: PropTypes.func
};

export default FailedRequest;
export {FailedRequest as Pure};
