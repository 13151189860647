import React from 'react';
import {render} from 'react-dom';
import { createBrowserHistory } from 'history';
import {syncHistoryWithStore} from 'react-router-redux';
import Root from './root/root.component';
import configureStore from './store/configuration';
import './styles.scss';
import { Provider } from 'react-redux';

const store = configureStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

const rootElement = (
  <Provider store={store}>
    <Root store={store} history={history}/>
  </Provider>
);

export default render(
  rootElement,
  document.getElementById('root')
);

