import { createAction } from 'redux-actions';

export const Actions = {
  CLEAR_BANKING_AUTHENTICATION: 'CLEAR_BANKING_AUTHENTICATION',
  ADD_BANKING_AUTHENTICATION: 'ADD_BANKING_AUTHENTICATION',
};

export const clearBankingAuthentication = createAction(Actions.CLEAR_BANKING_AUTHENTICATION);
export const addBankingAuthentication = createAction(Actions.ADD_BANKING_AUTHENTICATION);

