import { Actions } from '../../actions/user/user.action';

const initialState = {
  firstName: '',
  lastName: '',
  thankyouMessage: '',
  welcomeMessage: ''
};

export default function userReducer(
  state = initialState,
  action = {}
){

  let beneProfile;
  switch (action.type) {
    case Actions.FETCH_USER_SUCCESS:
      beneProfile = {...state, ...action.payload.beneProfile};
      if(!beneProfile.brand){
        beneProfile.isInErrorState = true;
        beneProfile.brand = action.payload.urlBrand;
      }

      return beneProfile;
    case Actions.FETCH_USER_ERROR:
      return {
        isInErrorState: true,
        id: action.payload.id,
        brand: action.payload.urlBrand};
    case Actions.STORE_USER_ID:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
