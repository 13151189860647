/* istanbul ignore next */
import get from 'lodash/get';
function dispatchPageLoad(page) {
  // // TODO: remove this - needed for QA only
  // if (console && console.info) { // eslint-disable-line
  //   console.info(page); // eslint-disable-line
  // }

  /*try {
    // instantiate a CustomEvent with an eventType of “pageLoad”
    let event = new CustomEvent('pageLoad', {
      detail: {
        // set the value of the function’s page parameter to
        // a “page” property on the CustomEvent’s detail object
        page: page
      }
    });
    // dispatch the CustomEvent
    window.dispatchEvent(event);
  } catch (e) {
    // handle exceptions as needed
  }*/
}

const config = {
  vendors: [
    {
      api: {
        name: 'Adobe Tag Manager',
        pageView(eventName, params) {
          dispatchPageLoad( get(params, 'pageName', 'gift-landing') );
        },
        track() {
        },
        user() {
        }
      }
    }
  ],
  pageDefaults: routeState => {
    const paths = routeState.pathname.split('/');
    const timestamp = new Date();
    return {
      timestamp,
      siteName: '529_Gifting',
      pageName: (paths[2]) ? paths[1] : undefined
    };
  },
  pageViewEvent: 'pageLoad',
  debug: false
};

export default config;
