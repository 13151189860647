import {actions} from '../../actions/gift-email/gift-email.action';

export default function gifterEmailReducer(state = '',action = {}) {
  switch (action.type) {
    case actions.GIFT_EMAIL_CHANGED:
      return action.payload;
    default:
      return state;
  }
}
