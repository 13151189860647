import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import shared from '@trp-md529-gifting/trpgifting529';
import Main from '../templates/main/main.component';
import Landing from '../pages/landing/landing.container';
import notFound from '../pages/not-found/not-found.component';
import Amount from '../pages/amount/amount.container';
import Email from '../pages/email/email.container';
import GiftComplete from '../pages/gift-complete/gift-complete.container';
import GiftReady from '../pages/gift-ready/gift-ready.container';
import SelectAccount from '../pages/select-account/select-account.container';
import SelectOwner from '../pages/select-owner/select-owner.container';
import FailedRequest from '../pages/failed-request/failed-request.container';
import NoEligibleAccounts from '../pages/no-eligible-accounts/no-eligible-accounts.container';
import OauthRedirect from "../pages/oauthRedirect/oauthRedirect.container";
import MDDecomError from "../pages/DecomError/MDDecomError";
import NATDecomError from "../pages/DecomError/NATDecomError";
import AKDecomError from "../pages/DecomError/AKDecomError";

const {atoms: { GiftingSpinner }, services: { xhr } } = shared;

class Root extends Component {
  constructor() {
    super();
    this.state = {
      hasApiKey: false
    };
  }
  componentDidMount() {
    let self = this;
    const basePath = document.location.origin;
    const baseURL = `${basePath}/usis/go-tuition-gift`;
    const isLocalhost =
      window &&
      window.location.hostname.toLowerCase().indexOf('localhost') !== -1;
    const apiUrl = isLocalhost
      ? `${baseURL}/api-conf.json`
      : `${baseURL}/api-config-v1.json`;
    fetch(
      apiUrl,
      {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    ).then(function(response){
      return response.json();
    }).then(function(myJson) {
      xhr.setApiKey(myJson.apiKey);
      self.setState({
        hasApiKey: true
      });
    })
  };

  render() {
    const { store } = this.props;
    return this.state.hasApiKey ? (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/usis/go-tuition-gift/maryland-529" >
              <MDDecomError />
            </Route>
            <Route path="/usis/go-tuition-gift/national-529" >
              <NATDecomError />
            </Route>
            <Route path="/usis/go-tuition-gift/alaska-529" >
              <AKDecomError />
            </Route>
            <Route path="/usis/go-tuition-gift/:brand" >
            <Main>
              <Switch>
                <Route path="/usis/go-tuition-gift/:brand/oauth" component={OauthRedirect} />
                <Route path="/usis/go-tuition-gift/:brand/select-account/:id" component={SelectAccount} />
                <Route path="/usis/go-tuition-gift/:brand/select-owner/:id" component={SelectOwner} />
                <Route path="/usis/go-tuition-gift/:brand/amount/:id" component={Amount} />
                <Route path="/usis/go-tuition-gift/:brand/email/:id" component={Email} />
                <Route path="/usis/go-tuition-gift/:brand/ready-gift/:id" component={GiftReady} />
                <Route path="/usis/go-tuition-gift/:brand/complete-gift/:id" component={GiftComplete} />
                <Route path="/usis/go-tuition-gift/:brand/failed/:id" component={notFound} />
                <Route path="/usis/go-tuition-gift/:brand/failed-request/:id" component={FailedRequest} />
                <Route path="/usis/go-tuition-gift/:brand/no-eligible-accounts/:id" component={NoEligibleAccounts} />
                <Route path="/usis/go-tuition-gift/:brand/:id" component={Landing} />
              </Switch>
            </Main>
          </Route>
          </Switch>
        </Router>
      </Provider>
    ) : (
      <GiftingSpinner />
    );
  }
}

export default Root;

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
