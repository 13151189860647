import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import shared from '@trp-md529-gifting/trpgifting529';

import {clearBankingAccount, selectBankingAccount} from '../../store/actions/banking/account-action';
import { bankingAccountsRetrieved, clearBankingAccounts } from '../../store/actions/banking/accounts-action';
import { addBankingAuthentication, clearBankingAuthentication } from '../../store/actions/banking/authentication.action';
import { bankingAccountsReceived, resetBankingAccountsFlag } from '../../store/actions/banking/accounts-retrieved-action';
import {giftAmountChanged} from '../../store/actions/gift-amount/gift-amount.action';
import {gifterEmailChanged} from '../../store/actions/gift-email/gift-email.action';
import './landing.scss';
//import {BankingButton} from "./banking-button.component";

const { atoms: { BankingButton, BeneficiaryName, BeneficiaryProfilePicture, PlanTitle, GiftingSpinner }, molecules: { ShowMore }, services: { xhr } } = shared;

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    banks: state.banks,
    bankingAccount: state.bankingAccount,
    bankingAccounts: state.bankingAccounts,
    bankingAuthentication: state.bankingAuthentication,
    bankingAccountsReceived: state.bankingAccountsReceived
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onClearBankingAccount: () => {
      dispatch(clearBankingAccount());
    },
    onClearBankingAccounts: () => {
      dispatch(clearBankingAccounts());
    },
    onAddBankingAccount: (payload) => {
      dispatch(selectBankingAccount(payload));
    },
    onAddBankingAccounts: (payload) => {
      dispatch(bankingAccountsRetrieved(payload));
    },
    onAddBankingAuthentication: (payload) => {
      dispatch(addBankingAuthentication(payload));
    },
    onClearBankingAuthentication: () => {
      dispatch(clearBankingAuthentication());
    },
    onBankingAccountsReceivedFlag: (payload) => {
      dispatch(bankingAccountsReceived(payload));
    },
    onResetBankingAccountsFlag: () => {
      dispatch(resetBankingAccountsFlag(null));
    },
    resetAmount: () => {
      dispatch(giftAmountChanged(0));
    },
    resetEmail: () => {
      dispatch(gifterEmailChanged(''));
    }
  };
};

export function getText(fieldName, options) {
  return (options) ? I18n.t(fieldName, options) : I18n.t(fieldName);
}

class Landing extends Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      pictureVar: undefined
    };

    this.setIsLoading = this.setIsLoading.bind(this);
  }

  componentWillMount(){
    this.props.resetAmount();
    this.props.resetEmail();
  }

  componentDidMount() {
    if(get(this.props.user, 'isInErrorState') || get(this.props.user, 'setupStatus') === false || get(this.props.user, 'displayed') === false){
      this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/failed/${get(this.props.user, 'giftAccountGUID')}`);
      return;
    }
    if (!this.props.user.giftAccountGUID.includes('oauth')) {
      window.localStorage.setItem('giftAccountID', this.props.user.giftAccountGUID);
    }
    if(!this.props.user.picture) {
      return;
    }
    const splitString = this.props.user.picture.split('api/');
    const path = splitString[splitString.length - 1];
    xhr.buildPath(`529-investment-gift-service/v1/api/${path}`);
    const getImg = async () => {
      const resp = await fetch(xhr.buildPath(`529-investment-gift-service/v1/api/${path}`), { headers : { apiKey: xhr.getApiKey(), 'X-TRP-REQUESTED-WITH': 'gift529'}});
      const blob = await resp.blob();
      this.setState({
        pictureVar: URL.createObjectURL(blob)
      });
    };
    getImg();
    this.props.onClearBankingAccount();
    this.props.onClearBankingAccounts();
    this.props.onResetBankingAccountsFlag();
    this.props.onClearBankingAuthentication();
  }

  setIsLoading(value) {
    this.setState({ isLoading: value });
  }

  render() {
    const defaultClassName = this.state.isLoading ? 'gifting-landing-loading' : 'gifting-landing';
    return (
      <div className={defaultClassName}>
        {this.state.isLoading && <div className="text-center landing-loader gifting-page-layout"><GiftingSpinner /></div>}
        <BeneficiaryProfilePicture firstName={get(this.props.user, 'firstName')} pictureSrc={this.state.pictureVar}/>
        <div className="text-center">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <BeneficiaryName firstName={get(this.props.user, 'firstName')}/>
              <PlanTitle/>
              <ShowMore
                authorName={get(this.props.user, 'ahName')}
                welcomeMessage={get(this.props.user, 'welcomeMessage')}
              />
            </div>
          </div>
          <div className="row button-row">
            <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
              <a
                href={getText(`gifter.landing.mailUrl`)}
                className="btn btn-primary btn-block"
                target="_blank"
                rel="noreferrer"
              >
                <Translate value="main.secondaryBtnTitle"/>
              </a>
              <div className="v-space-30"/>
              <BankingButton {...this.props} setIsLoading={this.setIsLoading} environment={window.TRP_AWS_ENVIRONMENT} />
            </div>
          </div>
          <div className="banking-disclosure">
            <Translate value="main.onlineGiftDisclosure"/>
          </div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  user: PropTypes.object,
  bankingAccount: PropTypes.object,
  bankingAccounts: PropTypes.object,
  onClearBankingAccount: PropTypes.func,
  onClearBankingAccounts: PropTypes.func,
  onAddBankingAccount: PropTypes.func,
  onResetBankingAccountsFlag: PropTypes.func,
  onClearBankingAuthentication: PropTypes.func,
  redirect: PropTypes.func,
  resetAmount: PropTypes.func,
  resetEmail: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
export {Landing as LandingContainer};
