import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {closeDialog, openDialog} from 'redux-dialog';
// import {AmountDisplayMode} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';
import {saveGift} from '../../store/actions/gift/gift.action';
import './gift-ready.scss';
const {atoms: { AmountDisplayMode } } = shared;

export const mapStateToProps = (state) => {
  return {
    aem: state.aem,
    giftAmount: state.giftAmount,
    user: state.user,
    bankingAccount: state.bankingAccount,
    bankingAccountOwner: state.bankingAccountOwner,
    gifterEmail: state.gifterEmail,
    bankingAuthentication: state.bankingAuthentication
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSendGift: (eventPayload, success, failure) => {
      return saveGift(eventPayload, success, failure);
    },
    openTermsDialog: () => {
      dispatch(openDialog('TermsAndConditionsDialog'));
    },
    closeTermsDialog: () => {
      dispatch(closeDialog('TermsAndConditionsDialog'));
    }
  };
};

class GiftReady extends Component {
  constructor() {
    super();
    this.state = {
      sending: false
    };
    this.onGift = this.onGift.bind(this);
    this.redirect = this.redirect.bind(this);
  }
  onGift() {
    this.save();
  }

  redirect() {
    this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/complete-gift/${this.props.user.giftAccountGUID}`);
  }

  save() {
    const { bankingAuthentication: { authenticationId } } = this.props;
    const giftPayload = {
      giftAccountGUID: this.props.user.giftAccountGUID,
      giftAmount: this.props.giftAmount,
      note: 'Gift of ' + this.props.giftAmount + ' given.',
      gifterProfile: {
        emailAddress: this.props.gifterEmail
      },
      authenticationId,
      bankAccount: {
        bankAccountNumber: this.props.bankingAccount.accountNumber,
        referenceID: this.props.bankingAccount.referenceId,
        routingNumber: this.props.bankingAccount.routingNumber,
        ownerName: this.props.bankingAccountOwner,
        bankType: this.props.bankingAccount.accountType,
        ownerNameSelectedIndicator: !isEmpty(get(this.props.bankingAccount, 'ownerNames', []))
      }
    };
    this.setState({
      sending: true
    });
    this.props.onSendGift(giftPayload, () => {
      this.setState({
        sending: false
      });
      this.redirect();
    }, () => {
      this.setState({
        sending: false
      });
      this.showError();
    });
  }

  showError() {
    this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/failed-request/` + this.props.user.giftAccountGUID);
  }

  getButtonTitle() {
    return !this.state.sending ? <Translate value="gifter.giftReady.sendGiftButtonText"/> : <Translate value="gifter.giftReady.sendGiftButtonTextSending" dangerousHTML/>;
  }

  render() {
    const buttonClass = classNames({
      'btn btn-default btn-block gifting-gift-ready__send-gift-button': !this.state.sending,
      'btn btn-default btn-block btn-loading gifting-gift-ready__send-gift-button': this.state.sending
    });

    return (
      <div className="gifting-gift-ready text-center gifting-page-layout">
        <div className="gifting-gift-ready__progress-icon"/>

        <div className="v-space-40"/>

        <h2 className="gifting-gift-ready__title"><Translate value="gifter.giftReady.title" dangerousHTML/></h2>

        <div className="v-space-30"/>

        <AmountDisplayMode prefix={I18n.t('gifter.giftReady.prefix')}
                           suffix={I18n.t('gifter.giftReady.suffix')}
                           value={this.props.giftAmount}/>

        <div className="v-space-30"/>

        <div className="row">
          <div className="col-md-6 col-xs-10 col-xxs-12 col-md-offset-3 col-xs-offset-1 col-xxs-offset-0">
            <div className="gifting-gift-ready__conditions">
              {/* eslint-disable react/no-danger */}
              <span className="gifting-gift-ready__conditions-content"
                    dangerouslySetInnerHTML={{__html: I18n.t('gifter.giftReady.termsConditionsAndTransferAgreementTextPartOne')}}/>
              {/* eslint-disable-next-line */}
              <a className="gifting-gift-ready__conditions-link"
                 dangerouslySetInnerHTML={{__html: I18n.t('gifter.giftReady.termsAndConditionsLinkText')}}
                 onClick={(e) => {e.preventDefault();this.props.openTermsDialog();}} href="#"/>
              <span className="gifting-gift-ready__conditions-content"
                    dangerouslySetInnerHTML={{__html: I18n.t('gifter.giftReady.termsConditionsAndTransferAgreementTextPartTwo')}}/>
              {/* eslint-enable react/no-danger */}
            </div>
          </div>
        </div>

        <div className="v-space-40"/>

        <div className="row">
          <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
            <button className={buttonClass} onClick={this.onGift} disabled={this.state.sending}>
              {this.getButtonTitle()}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

GiftReady.propTypes = {
  aem: PropTypes.object,
  user: PropTypes.object,
  giftAmount: PropTypes.number,
  bankingAccount: PropTypes.object,
  bankingAuthentication: PropTypes.object,
  bankingAccountOwner: PropTypes.string,
  gifterEmail: PropTypes.string,
  onSendGift: PropTypes.func,
  openTermsDialog: PropTypes.func,
  closeTermsDialog: PropTypes.func,
  params: PropTypes.object,
  redirect: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftReady);
export {GiftReady as Pure};
