import React from 'react';
import './DecomError.scss';
import AlertIconImage from "./alert-icon.image";

const NATDecomError = () => {
  return <div
    className={'full-page-loader-loading-screen full-page-loader-error national-529'}
    style={{ position: 'absolute' }}
  >
    <div className="full-page-loader-error-container" id="errorContainer">
      <div className="full-page-loader-icon">
        <AlertIconImage />
      </div>
      <h2 className="error-page-title">T. Rowe Price College Savings Plan account access steps have changed.</h2>
      <div className="error-page-text-points">
        Review this{' '}
        <a
          href={'https://cdn.unite529.com/jcdn/files/PDF/pdfs/TRPCSP/T_Rowe_Price_College_Savings_Plan_Account_Holder_Transition_Guide.pdf'}
        >
          T. Rowe Price College Savings Plan Transition Guide
        </a>
        {' '}for details and next steps.
      </div>
    </div>
  </div>
}

export default NATDecomError;