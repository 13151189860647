import { createAction } from 'redux-actions';

export const Actions = {
  SELECT_BANKING_ACCOUNT: 'SELECT_BANKING_ACCOUNT',
  CLEAR_BANKING_ACCOUNT: 'CLEAR_BANKING_ACCOUNT',
};

export const selectBankingAccount = createAction(Actions.SELECT_BANKING_ACCOUNT);
export const clearBankingAccount = createAction(Actions.CLEAR_BANKING_ACCOUNT);

