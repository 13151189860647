import replace from 'lodash/replace';
// import {xhr, END_POINTS, ucfirst} from 'trp-components';
// import {xhr, END_POINTS, ucfirst} from '@trp-md529-gifting/trpgifting529';
import shared from '@trp-md529-gifting/trpgifting529';
import { createAction } from 'redux-actions';
const { services: { xhr, END_POINTS, ucfirst } } = shared;

export const Actions = {
  FETCH_AEM_DATA_SUCCESS: 'FETCH_AEM_DATA_SUCCESS',
  FETCH_AEM_DATA_ERROR: 'FETCH_AEM_DATA_ERROR',
};

export const fetchAemDataSuccess = createAction(Actions.FETCH_AEM_DATA_SUCCESS);
export const fetchAemDataError = createAction(Actions.FETCH_AEM_DATA_ERROR);

export function fetchAemData(brand){
  return dispatch => {
    return xhr.get(END_POINTS.AEM_GIFTING_GIFTER, replace(ucfirst(brand), ' ', ''), null, true)
      .then((response) => {
        dispatch(fetchAemDataSuccess(response.data.message));
      })
      .catch((error) => {
        dispatch(fetchAemDataError(error.response));
      });
  };
}
