import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n} from 'react-redux-i18n';
// import {GiftGrowthChart, Input} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';
import './amount.scss';
const {molecules: { GiftGrowthChart }, atoms: { InputContainer } } = shared;

const amount = (props) => {
  const getText = (fieldName, options = {}) => {
    return I18n.t(`gifter.amount.${fieldName}`, options);
  };

  return (
    <div className="gifting-amount text-center gifting-page-layout">
      <div className="gifting-amount__progress-icon"/>

      <div className="v-space-30"/>

      <h2 className="gifting-amount__title">{getText('title')}</h2>

      <div className="v-space-30"/>

      <div className="row">
        <div
          className="col-xxs-12 col-xs-10 col-sm-8 col-md-6 col-lg-4 col-xs-offset-1 col-xxs-offset-0 col-sm-offset-2 col-md-offset-3 col-lg-offset-4">
          <InputContainer
            type="tel"
            name="giftAmount"
            onChange={props.onAmountInputChange}
            value={props.giftAmount}
            prefix={getText('prefix')}
            suffix={getText('suffix')}
            guidanceText={getText('guidanceText', {amount: props.minAmount})}
            range={[props.minAmount, props.maxAmount]}
            validationMessages={{
              max: getText('validationMessages.max', {amount: props.maxAmount.toLocaleString()})
            }}
          />
        </div>
      </div>

      <div className="v-space-50"/>

      <div className="row">
        <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
          <button
            className="btn btn-default btn-block"
            onClick={props.redirect}
            disabled={!props.isButtonEnabled}
          >
            {getText('button')}
          </button>
        </div>
      </div>

      <div className="v-space-50"/>
      <div className="v-space-20"/>

      <div className="row">
        <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
          <GiftGrowthChart
            title={get(props, `aem['gift-growth-chart'].title`)}
            legend={get(props, `aem['gift-growth-chart'].legend`)}
            data={get(props, `aem['gift-growth-chart'].data`)}
          />

          <div className="v-space-20"/>

          {/* eslint-disable react/no-danger */}
          <p className="caption"
             dangerouslySetInnerHTML={{__html: get(props, `aem['gift-growth-disclosure']`)}}/>
          {/* eslint-enable react/no-danger */}
        </div>
      </div>
    </div>
  );
};

amount.propTypes = {
  isButtonEnabled: PropTypes.bool,
  giftAmount: PropTypes.number,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
  aem: PropTypes.object,
  onAmountInputChange: PropTypes.func,
  redirect: PropTypes.func
};

export {amount as AmountComponent};
