import assign from 'lodash/assign';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import shared from '@trp-md529-gifting/trpgifting529';
import { createAction } from 'redux-actions';
const { services: { xhr, END_POINTS } } = shared;


export const Actions = {
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  STORE_USER_ID: 'STORE_USER_ID'
};

export function fetchUser(id, urlBrand){
  return dispatch => {
    return xhr.get(END_POINTS.API_SECURE_BENEFICIARIES_GIFT_ACCOUNTS_ID, id)
      .then((response) => {
        dispatch(fetchUserSuccess({beneProfile:  convertPayloadToModel(response.data), urlBrand} ));
      })
      .catch(() => {
        dispatch(fetchUserError({id, urlBrand}));
      });
  };
}

export function convertPayloadToModel(payload) {
  let model = assign({}, payload.giftAccount);
  model = assign(model, payload.beneficiary);
  if (get(model, 'themeName')) {
    model.theme = model.themeName;
    delete model.themeName;
  }

  let accountHolderFirstName = get(payload, 'accountHolder.firstName');
  if (!isEmpty(accountHolderFirstName)) {
    model.ahName = accountHolderFirstName;
  }

  if (get(model, 'image')) {
    model.picture = model.image;
    delete model.image;
  }

  model.brand = toLower(get(model, 'collegeSavingsProductType', 'MARYLAND')) + '-529';
  return model;
}

export const fetchUserSuccess = createAction(Actions.FETCH_USER_SUCCESS);
export const fetchUserError = createAction(Actions.FETCH_USER_ERROR);
export const storeUserId = createAction(Actions.STORE_USER_ID);
