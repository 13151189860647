import filter from 'lodash/filter';
import includes from 'lodash/includes';
import upperCase from 'lodash/upperCase';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
// import {xhr, END_POINTS, ucfirst} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';
import { createAction } from 'redux-actions';
const { services: { xhr, END_POINTS, ucfirst } } = shared;

export const Actions = {
  BANKING_ACCOUNTS_RETRIEVED: 'BANKING_ACCOUNTS_RETRIEVED',
  BANKING_ACCOUNTS_RETRIEVAL_ERROR: 'BANKING_ACCOUNTS_RETRIEVAL_ERROR',
  CLEAR_BANKING_ACCOUNTS: 'CLEAR_BANKING_ACCOUNTS'
};

export const bankingAccountsRetrieved = createAction(Actions.BANKING_ACCOUNTS_RETRIEVED);
export const bankingAccountsRetrievalError = createAction(Actions.BANKING_ACCOUNTS_RETRIEVAL_ERROR);
export const clearBankingAccounts = createAction(Actions.CLEAR_BANKING_ACCOUNTS);

const desiredAccountTypes = ['SAVINGS', 'CHECKING'];

function selectOnlyDesiredAccountTypes(payload) {
  return filter(payload, (account) => includes(desiredAccountTypes,upperCase(account.accountType)));
}

function addAccountTypeDisplayToAccount(account) {
  let formattedAccount = cloneDeep(account);
  formattedAccount.accountTypeDisplay = ucfirst(account.accountType);
  return formattedAccount;
}

function addAccountTypeDisplayToAccounts(payload) {
  return map(payload, addAccountTypeDisplayToAccount);
}

function fromPayload(payload){
  return addAccountTypeDisplayToAccounts(selectOnlyDesiredAccountTypes(payload));
}

export function retrieveObfuscatedAccountInformation(authenticationId){
  return dispatch => {
    return xhr.post(END_POINTS.API_SECURE_BANKING_OBFUSCATED_ACCOUNT_INFO, null, authenticationId)
      .then((response) => {
        dispatch(bankingAccountsRetrieved( fromPayload(response.data) ));
      })
      .catch((error) => {
        dispatch(bankingAccountsRetrievalError(error.response));
      });
  };
}

