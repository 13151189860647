const AlertIconImage = () => (
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.9409 61.3145C64.3412 61.8709 64.3672 62.6136 64.0066 63.1965C63.646 63.7794 62.9699 64.0879 62.2933 63.9781H2.70679C2.03022 64.0879 1.35409 63.7794 0.993519 63.1965C0.632943 62.6136 0.658874 61.8709 1.05923 61.3145L31.1683 1.09937C31.9012 -0.366457 33.0989 -0.366457 33.8318 1.09937L63.9409 61.3145ZM32.5008 18.7313C34.0434 18.7313 35.2939 19.9819 35.2939 21.5245V42.3797C35.2939 43.9223 34.0434 45.1729 32.5008 45.1729C30.9582 45.1729 29.7077 43.9223 29.7077 42.3797V21.5245C29.7077 19.9819 30.9582 18.7313 32.5008 18.7313ZM32.4181 50.0148C32.4396 50.0144 32.4611 50.0142 32.4824 50.0143L32.5525 50.0148C34.4345 50.0502 35.9622 51.5474 36.0357 53.4283L36.0359 53.4346C36.0707 54.3796 35.7244 55.299 35.0749 55.9862C34.4254 56.6735 33.527 57.0711 32.5815 57.0896L32.5267 57.0902L32.503 57.0902C30.5993 57.0842 29.0387 55.5788 28.964 53.6766L28.9638 53.6698C28.929 52.7249 29.2753 51.8055 29.9248 51.1182C30.5743 50.431 31.4727 50.0334 32.4181 50.0148Z"
      fill="#FFAD00"
    />
  </svg>
);

export default AlertIconImage;
