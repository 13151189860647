import { Actions } from '../../actions/aem/aem.action';

export default function aemReducer(state = {}, action = {}) {
  switch (action.type) {
    case Actions.FETCH_AEM_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case Actions.FETCH_AEM_DATA_ERROR:
      return state;
    default:
      return state;
  }
}
