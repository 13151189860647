import { createAction } from 'redux-actions';

export const Actions = {
  SELECT_ACCOUNT_OWNER: 'SELECT_ACCOUNT_OWNER',
  CLEAR_ACCOUNT_OWNER: 'CLEAR_ACCOUNT_OWNER',
};

export const selectAccountOwner = createAction(Actions.SELECT_ACCOUNT_OWNER);
export const clearAccountOwner = createAction(Actions.CLEAR_ACCOUNT_OWNER);

