import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Translate} from "react-redux-i18n";
// import {RadioButton, numberFormatAmount, GiftingSpinner} from "trp-components";
import shared from '@trp-md529-gifting/trpgifting529';
import {selectBankingAccount} from "../../store/actions/banking/account-action";

import "./select-account.scss";
const { atoms: { RadioButton, GiftingSpinner }, services: { numberFormatAmount } } = shared;

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    bankingAccount: state.bankingAccount,
    bankingAccounts: state.bankingAccounts,
    bankingAccountsReceived: state.bankingAccountsReceived
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    selectAccount: (account) => {
      dispatch(selectBankingAccount(account));
    }
  };
};

// export function bankingAuthenticationFailed(nextProps) {
//   return bankingAuthenticationEqualsState(nextProps, 'FAILED');
// }
//


class SelectAccount extends Component {
  componentWillReceiveProps(nextProps) {
    window.localStorage.removeItem('linkToken');
    window.localStorage.removeItem('giftAccountID');
    if (isEmpty(this.props.bankingAccounts)) {
      if (nextProps.bankingAccountsReceived !== null && !nextProps.bankingAccountsReceived) {
        this.props.history['replace'](`/usis/go-tuition-gift/${this.props.user.brand}/no-eligible-accounts/${this.props.user.giftAccountGUID}`);
      }
    }
  }

  onChange(event) {
    const input = get(event, 'target', {});
    const referenceId = get(input, 'value', '');
    this.props.selectAccount(find(this.props.bankingAccounts.accounts, {referenceId: referenceId} ));
  }

  onClick() {
    this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/select-owner/${this.props.user.giftAccountGUID}`);
  }

  render() {
    if (this.props.bankingAccounts?.accounts?.length === 1){
      this.props.selectAccount(this.props.bankingAccounts.accounts[0]);
      this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/select-owner/${get(this.props.user, 'giftAccountGUID')}`);
    }
    return (
      isEmpty(get(this.props.bankingAccounts, 'accounts')) ?
        ( <div className="text-center select-account-loading gifting-page-layout"><GiftingSpinner /></div>):
        ( <div className="text-center select-account gifting-page-layout">
            <div className="row">
              <div className="col-md-8 col-xs-8 col-xxs-10 col-md-offset-2 col-xs-offset-2 col-xxs-offset-1">
                <h2 className="select-account__title"><Translate value="gifter.selectAccount.title" dangerousHTML/></h2>
              </div>
            </div>

            <div className="v-space-40"/>
            <div className="row">
              <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
                <div className="accounts-container">
                  {this.props.bankingAccounts.accounts.map((account) =>
                    (<RadioButton key={'radio-key-'+account.referenceId}
                                  name="select-account"
                                  id={'radio-'+account.referenceId}
                                  value={account.referenceId}
                                  label={account.accountType}
                                  secondaryLabel={account.accountNumber}
                                  tertiaryLabel={numberFormatAmount(account.value, {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                  isChecked={isEqual(get(this.props.bankingAccount, 'referenceId'), account.referenceId)}
                                  onChange={this.onChange.bind(this)}/>)
                  )}
                </div>
              </div>
            </div>

            <div className="v-space-20"/>

            <div className="row">
              <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
                <button
                  className="btn btn-default btn-block"
                  onClick={this.onClick.bind(this)}
                  disabled={isEmpty(this.props.bankingAccount)}
                >
                  <Translate value="gifter.selectAccount.button"/>
                </button>
              </div>
            </div>
          </div>
        )
    );
  }
}

SelectAccount.propTypes = {
  user: PropTypes.object,
  urlBrand: PropTypes.string,
  bankingToken: PropTypes.object,
  bankingAccount: PropTypes.object,
  bankingAccounts: PropTypes.object,
  bankingAuthentication: PropTypes.object,
  bankingAccountsReceived: PropTypes.bool,
  onClearBankingAuthentication: PropTypes.func,
  onClearBankingAccount: PropTypes.func,
  onClearBankingAccounts: PropTypes.func,
  selectAccount: PropTypes.func,
  checkBankingAuthenticationStatus: PropTypes.func,
  retrieveObfuscatedAccountInformation: PropTypes.func,
  redirect: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccount);
export {SelectAccount as Pure};
