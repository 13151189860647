import { Actions } from '../../actions/banking/accounts-retrieved-action';

export default function accountsReceivedReducer(state = null, action = {}) {
  switch (action.type) {
    case Actions.BANKING_ACCOUNTS_SUCCESS:
      return action.payload;
    case Actions.RESET_BANKING_ACCOUNTS_FLAG:
      return null;
    default:
      return state;
  }
}
