import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import reduxDialog, {closeDialog, openDialog} from 'redux-dialog';
// import {BasicDialog, Footer as SharedFooter, moment} from 'trp-components';
//import {BasicDialog, Footer as SharedFooter, moment} from '@trp-md529-gifting/trpgifting529';
import shared from '@trp-md529-gifting/trpgifting529';
import {fetchCompanyContact} from '../../store/actions/company-contacts/company-contacts.action';
import './footer.scss';

const { organisms: { BasicDialog, Footer: SharedFooter }, services: {moment} } = shared;

const TermsAndConditionsDialog = reduxDialog({
  overlayClassName: 'ReactModal__Overlay',
  className: 'modal-dialog',
  style: {overlay: null, content: {}},
  contentLabel: '',
  name: 'TermsAndConditionsDialog',
  parentSelector: /* istanbul ignore next */ () => document.getElementById('main')
})(BasicDialog);

const PrivacyPolicyDialog = reduxDialog({
  overlayClassName: 'ReactModal__Overlay',
  className: 'modal-dialog',
  style: {overlay: null, content: {}},
  contentLabel: '',
  name: 'PrivacyPolicyDialog',
  parentSelector: /* istanbul ignore next */ () => document.getElementById('main')
})(BasicDialog);

export const mapStateToProps = (state) => {
  return {
    aem: state.aem,
    companyContacts: state.companyContacts
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyContact: (brand) => {
      dispatch(fetchCompanyContact(brand));
    },
    openDialog: (dialog) => {
      dispatch(openDialog(dialog));
    },
    closeDialog: (dialog) => {
      dispatch(closeDialog(dialog));
    }
  };
};

export function formatAvailability(contact) {
  const startDay = get(contact, 'hoursOfOperations[0].dayOfWeekRange.startDay');
  const endDay = get(contact, 'hoursOfOperations[0].dayOfWeekRange.endDay');
  const startTime = get(contact, 'hoursOfOperations[0].timeRange.minimum');
  const endTime = get(contact, 'hoursOfOperations[0].timeRange.maximum');
  const formattedStartTime = moment.tz(startTime, 'America/New_York').format('h:mm a');
  const formattedEndTime = moment.tz(endTime, 'America/New_York').format('h:mm a z');

  return `${startDay} through ${endDay}, ${formattedStartTime} to ${formattedEndTime}`;
}

export function formatPhone(contact) {
  const areaCode = get(contact, 'phoneNumber.areaCode');
  const localNumber = get(contact, 'phoneNumber.localNumber');
  const exchange = get(contact, 'phoneNumber.exchange');

  return `${exchange} (${areaCode}-${localNumber})`;
}

export function insertPhone(source, contact) {
  let output;
  if (!source || isEmpty(contact)) {
    output = '';
  } else {
    output = source.replace('%{phone}', formatPhone(contact));
  }
  return output;
}

export function getText(fieldName, options) {
  return (options) ? I18n.t(fieldName, options) : I18n.t(fieldName);
}

export class FooterContainer extends Component {
  componentWillMount() {
      this.props.fetchCompanyContact('maryland-529');
  }

  render() {
    return (
      <footer className="gifting-footer">

        <div className="gifting-footer__ETA-logo"/>

        <PrivacyPolicyDialog
          id="privacyPolicyModal"
          name="PrivacyPolicyDialog"
          close={this.props.closeDialog.bind(this)}
          content={get(this.props, `aem['privacy-policy']`)}
          label={getText('shared.footer.privacyPolicyLabel')}
        />

        <TermsAndConditionsDialog
          id="termsAndConditionModal"
          name="TermsAndConditionsDialog"
          close={this.props.closeDialog.bind(this)}
          content={get(this.props, `aem['terms-and-conditions']`)}
          label={getText('shared.footer.termsAndConditionLabel')}
        />

        <SharedFooter
          openDialog={this.props.openDialog}
          privacyLabel={getText('shared.footer.privacyPolicyLabel')}
          termsLabel={getText('shared.footer.termsAndConditionLabel')}
          feedbackLabel={getText('shared.footer.feedback')}
          contactLabel={getText('shared.footer.contactLabel')}
          disclosureStatementLabel={getText('shared.footer.disclosureStatementLabel')}
          contactContent={insertPhone(get(this.props, `aem['contact']`), this.props.companyContacts)}
          disclosureContent={insertPhone(get(this.props, `aem['disclosure']`), this.props.companyContacts)}
        />

        <div className="gifting-footer__go-tuition-logo"/>
      </footer>
    );
  }
}

FooterContainer.propTypes = {
  companyContacts: PropTypes.object,
  aem: PropTypes.object,
  brand: PropTypes.string,
  fetchCompanyContact: PropTypes.func,
  openDialog: PropTypes.func,
  closeDialog: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
