import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from "redux-thunk";
import rootReducer from './reducers';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
// import { translationsObject } from '@trp-md529-gifting/trpgifting529';
import shared from '@trp-md529-gifting/trpgifting529';
const { services: { translationsObject } } = shared;

export function applyCoreConfiguration(store) {
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  store.dispatch(setLocale('en'));
}

/* istanbul ignore next */
export function configureStoreProd(initialState) {
  const middleware = [
    thunk
  ];

  const store =  createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));

  applyCoreConfiguration(store);

  return store;
}

export function configureStoreDev(initialState) {
  const middleware = [
    reduxImmutableStateInvariant(),
    thunk,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

  applyCoreConfiguration(store);

  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
