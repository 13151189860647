import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {usePlaidLink} from 'react-plaid-link';
import shared from '@trp-md529-gifting/trpgifting529';
import {bankingAccountsRetrieved} from '../../store/actions/banking/accounts-action';
import {bankingAccountsReceived} from '../../store/actions/banking/accounts-retrieved-action';
import {addBankingAuthentication} from "../../store/actions/banking/authentication.action";
import {selectBankingAccount} from "../../store/actions/banking/account-action";

const {services: {xhr, END_POINTS}} = shared;


const consumer = {
  "bu": "RETAIL",
  "name": "529_Gift"
};

const OauthRedirect = (props) => {
  // The Link token from the first Link initialization
  const dispatch = useDispatch();
  const authenticationId = useSelector((state) => state.bankingAuthentication.authenticationId);
  const linkToken = window.localStorage.getItem('linkToken');
  const giftId = window.localStorage.getItem('giftAccountID');

  const onSuccess = (token, metadata) => {
    const payload = {
      authenticationId: authenticationId,
      metadata,
      'public_token': token,
      'consumer': {...consumer}
    };

    xhr.post(END_POINTS.API_SECURE_BANKING_ON_SUCCESS, null, payload)
      .then((response) => {
        const {data} = response;
        if (data) {
          if(data?.length === 1) {
            dispatch(selectBankingAccount(data[0]));
          }
          dispatch(bankingAccountsRetrieved(data));
          dispatch(bankingAccountsReceived(true));
        }
      })
      .catch((error) => {
        dispatch(bankingAccountsReceived(false));
      });
  }

  const onExit = (err, metatdata) => {
    // handle error...
  };

  const onEvent = (eventName, metadata) => {
    /*if (eventName === 'OPEN') {
      this.props.setIsLoading(true);
    }
    if (eventName === 'EXIT') {
      this.props.setIsLoading(false);
    }*/
    if (eventName === 'HANDOFF') {
      props.history['replace'](`/usis/go-tuition-gift/${props.match.params.brand}/select-account/${giftId}`);
    }
    const payload = {
      authenticationId: authenticationId,
      metadata,
      eventName,
      'consumer': {...consumer}
    };
    if (authenticationId) {
      xhr.post(END_POINTS.API_SECURE_BANKING_ON_EVENT, null, payload)
        .then((response) => {
        })
        .catch((error) => {
          console.log("Error:" + error);
        });
    }
  }

  const onLoad =() => {
    dispatch(addBankingAuthentication(authenticationId));
  }

  const config = {
    token: linkToken,
    receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
    onSuccess,
    onExit,
    onEvent,
    onLoad
  };
  const {open, ready} = usePlaidLink(config);

  // automatically reinitialize Link
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <></>;
};

export default OauthRedirect;