import React from 'react';
import {Translate} from 'react-redux-i18n';
// import {BeneficiaryProfilePicture} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';

import './not-found.scss';
const { atoms: { BeneficiaryProfilePicture } } = shared;

const notFound = () => {
  return (
    <div className="gifting-not-found">
      <BeneficiaryProfilePicture firstName={''} pictureSrc={''}/>

      <div className="gifting-not-found__illustration"/>

      <div className="v-space-40"/>

      <div className="row">
        <div className="col-xs-12 col-sm-push-3 col-sm-6">
          <h2 className="text-center">
            <Translate value="gifter.failed.title" dangerousHTML/>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default notFound;
export {notFound as NotFoundComponent};
