import {Actions} from '../../actions/company-contacts/company-contacts.action';

export default function companyContactsReducer(state = {},
                                               action = {}) {
  switch (action.type) {
    case Actions.FETCH_COMPANY_CONTACT_SUCCESS:
      return { ...state, ...action.payload };
    case
    Actions.FETCH_COMPANY_CONTACT_ERROR
    :
      return state;
    default:
      return state;
  }
}
