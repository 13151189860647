import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import {I18n, Translate} from 'react-redux-i18n';
import shared from '@trp-md529-gifting/trpgifting529';
import {closeDialog, openDialog} from 'redux-dialog';
import {gifterEmailChanged} from '../../store/actions/gift-email/gift-email.action';
import './email.scss';

const { atoms: { Input } } = shared;

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    aem: state.aem,
    giftAmount: state.giftAmount,
    gifterEmail: state.gifterEmail
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    gifterEmailChanged: (email) => {
      dispatch(gifterEmailChanged(email));
    },
    openDialog: (dialog) => {
      dispatch(openDialog(dialog));
    },
    closeDialog: (dialog) => {
      dispatch(closeDialog(dialog));
    }
  };
};

class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      email: this.props.gifterEmail || ''
    };
  }
  componentWillMount() {
    if (!this.props.giftAmount) {
      this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/amount/${this.props.user.giftAccountGUID}`);
    }
  }

  onChange(event) {
    this.setState({
      hasError: false,
      email: get(event, 'target.value', '')
    });
  }

  onBlur(event) {
    if (this.state.email !== get(event, 'target.value')) {
      this.onChange(event);
    }
  }

  validateEmail(event) {
    event.preventDefault();
    if (isEmpty(this.state.email) || isEmail(this.state.email)) {
      this.props.gifterEmailChanged(this.state.email);
      this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/ready-gift/${this.props.user.giftAccountGUID}`);
    } else {
      this.setState({hasError: true});
    }
  }

  render() {
    return (
      <form className="gifting-email text-center gifting-page-layout" onSubmit={this.validateEmail.bind(this)}>
        <div className="gifting-email__progress-icon"/>

        <div className="v-space-30"/>
        <div className="row">
          <div className="col-md-4 col-md-offset-4 col-xs-12">
            <h2><Translate value="gifter.email.title"/></h2>
          </div>
        </div>
        <div className="v-space-40"/>
        <div className="row">
          <div className="col-md-4 col-md-offset-4 col-xs-12">
            <Input
              type="email"
              name="email"
              placeholder={I18n.t('gifter.email.placeholderText')}
              value={this.state.email}
              guidanceText={''}
              handleChange={this.onChange.bind(this)}
              handleBlur={this.onBlur.bind(this)}
              maxLength={50}
              validation={this.state.hasError ? [I18n.t('gifter.email.invalidEmailMsg')] : null}
              autocomplete="on"
            />
          </div>
        </div>
        <div className="v-space-40"/>
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-xs-12">
            <div className="gifting-email__disclosure">
              <Translate value="gifter.email.disclosure" dangerousHTML/>
              {/* eslint-disable-next-line */}
              <a className="gifting-gift-email__disclosure-link" href="#" onClick={(e) => {e.preventDefault();this.props.openDialog('PrivacyPolicyDialog');}}>
                <Translate value="gifter.email.privacyPolicyLabel"/>
              </a>.
            </div>
            <div className="v-space-40"/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-md-offset-4 col-xs-12">
            <button
              className="btn btn-default btn-block"
              onClick={this.validateEmail.bind(this)}
            >
              <Translate value="gifter.email.nextButton"/>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

Email.propTypes = {
  user: PropTypes.object,
  giftAmount: PropTypes.number,
  params: PropTypes.object,
  gifterEmail: PropTypes.string,
  redirect: PropTypes.func,
  gifterEmailChanged: PropTypes.func,
  openDialog: PropTypes.func,
  closeDialog: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
export { Email as EmailContainer };
