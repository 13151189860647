import { i18nReducer } from 'react-redux-i18n';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import aemReducer from './aem/aem.reducer';
import companyContactsReducer from './company-contacts/company-contacts.reducer';
import userReducer from './user/user.reducer';
import authenticationReducer from './banking/authentication.reducer';
import accountReducer from './banking/account.reducer';
import ownerReducer from './banking/owner.reducer';
import accountsReducer from './banking/accounts.reducer';
import giftAmountReducer from './gift-amount/gift-amount.reducer';
import { dialogReducer } from 'redux-dialog';
import gifterReducer from './gift-email/gift-email.reducer';
import accountsReceivedReducer from './banking/accounts-received.reducer';

const rootReducer = combineReducers({
  bankingAccount: accountReducer,
  bankingAccountOwner: ownerReducer,
  bankingAccounts: accountsReducer,
  bankingAuthentication: authenticationReducer,
  bankingAccountsReceived: accountsReceivedReducer,
  routing: routerReducer,
  user: userReducer,
  i18n: i18nReducer,
  aem: aemReducer,
  companyContacts: companyContactsReducer,
  giftAmount: giftAmountReducer,
  gifterEmail: gifterReducer,
  dialogReducer
});

export default rootReducer;
