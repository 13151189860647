import { Actions } from '../../actions/banking/owner-action';

export default function ownerReducer(state = '', action = {}) {
  switch (action.type) {
    case Actions.SELECT_ACCOUNT_OWNER:
      return action.payload;
    case Actions.CLEAR_ACCOUNT_OWNER:
      return '';
    default:
      return state;
  }
}
