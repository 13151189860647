import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
// import {loadMicroMoment} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';
import {withRouter} from 'react-router';
import './gift-complete.scss';
const { services: { loadMicroMoment } } = shared;

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

class GiftComplete extends Component {
  /*componentWillMount(){
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this));
  }*/

  componentDidMount() {
    loadMicroMoment('gift-box', 'giftBox', this.props.user.brand.replace('-',''));
  }

  componentWillUnmount() {
    window.giftBox = undefined;
  }

  routerWillLeave(nextRoute) {
    if (this.isNavigateBack(nextRoute)) {
      this.redirectHome();
    }
  }

  isNavigateBack(route) {
    return route.action === 'POP';
  }

  redirectHome(){
    this.props.history['replace'](this.props.user.giftAccountGUID);
  }

  render() {
    return (
      <div className="gifting-gift-complete text-center gifting-page-layout">
        <div className="row">
          <div className="col-xs-12 text-center">
            <div id="animation_container" className="gifting-gift-complete__animation">
              <canvas id="canvas" className="gifting-gift-complete__animation-canvas"/>
              <div id="dom_overlay_container" className="gifting-gift-complete__animation-overlay"/>
            </div>
          </div>
        </div>

        <div className="v-space-20"/>

        <h2 className="gifting-gift-complete__title"><Translate value="gifter.giftComplete.title" firstName={this.props.user.firstName} dangerousHTML/>
        </h2>

        <div className="hidden-xs v-space-40"/>
        <div className="visible-xs v-space-30"/>

        <a href={I18n.t(`gifter.giftComplete.printButtonLink`)}
           className="btn btn-default gifting-gift-complete__print-button"
           target="_blank"
           rel="noreferrer"
        >
          <Translate value="gifter.giftComplete.printButtonText" dangerousHTML/>
        </a>

        <div className="v-space-30"/>

        <div>
          <span className="gifting-gift-complete__processing-time"><Translate value="gifter.giftComplete.processingTimeText" dangerousHTML/></span>
        </div>

        <div className="v-space-60"/>

        <a
          className="gifting-gift-complete__open-account-link"
          href={I18n.t(`gifter.giftComplete.openAnAccountTodayLink`)}
          target="_blank"
          rel="noreferrer"
        >
          <Translate value="gifter.giftComplete.openAnAccountTodayText" dangerousHTML/>
        </a>

      </div>
    );
  }
}

GiftComplete.propTypes = {
  user: PropTypes.object,
  router: PropTypes.object,
  route: PropTypes.object,
  redirect: PropTypes.func
};

export default withRouter(connect(mapStateToProps, null)(GiftComplete));
export {GiftComplete as GiftCompleteContainer};
