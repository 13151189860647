import { Actions } from '../../actions/banking/account-action';

export default function accountReducer(state = {}, action = {}) {
  switch (action.type) {
    case Actions.SELECT_BANKING_ACCOUNT:
      return { ...state, ...action.payload };
    case Actions.CLEAR_BANKING_ACCOUNT:
      return {};
    default:
      return state;
  }
}
