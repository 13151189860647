import React from 'react';
import './DecomError.scss';
import AlertIconImage from "./alert-icon.image";

const MDDecomError = () => {
 return <div
   className={'full-page-loader-loading-screen full-page-loader-error maryland-529'}
   style={{ position: 'absolute' }}
 >
  <div className="full-page-loader-error-container" id="errorContainer">
   <div className="full-page-loader-icon">
    <AlertIconImage />
   </div>
   <h2 className="error-page-title">We are unable to locate any active accounts</h2>
   <div className="error-page-text-points">
    The way that Maryland College Investment Plan Accounts are established and/or accessed
    has changed. For more information, please review the{' '}
    <a
      href={'https://cdn.unite529.com/jcdn/files/MDD/pdfs/Investment_Plan_Account_Holder_Transition_Guide.pdf'}
    >
     Transition Guide
    </a>.
   </div>
  </div>
 </div>
}

export default MDDecomError;