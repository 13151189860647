import React from 'react';
import './DecomError.scss';
import AlertIconImage from "./alert-icon.image";

const AKDecomError = () => {
    return <div
        className={'full-page-loader-loading-screen full-page-loader-error alaska-529'}
        style={{ position: 'absolute' }}
    >
        <div className="full-page-loader-error-container" id="errorContainer">
            <div className="full-page-loader-icon">
                <AlertIconImage />
            </div>
            <h2 className="error-page-title">Alaska 529 Plan account access steps have changed.</h2>
            <div className="error-page-text-points">
                Review this{' '}
                <a
                    href={'https://cdn.unite529.com/jcdn/files/AKD/pdfs/Alaska_529_Account_Owner_Transition_Guide.pdf'}
                >
                    Alaska 529 Transition Guide
                </a>
                {' '}for details and next steps.
            </div>
        </div>
    </div>
}

export default AKDecomError;