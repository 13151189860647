import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import shared from '@trp-md529-gifting/trpgifting529';
import {clearAccountOwner, selectAccountOwner} from "../../store/actions/banking/owner-action";
import './select-owner.scss';
const { atoms: { RadioButton, GiftingSpinner, input: InputComponent } } = shared;


export const mapStateToProps = (state) => {
  return {
    user: state.user,
    bankingAccount: state.bankingAccount,
    bankingAccounts: state.bankingAccounts,
    bankingAccountOwner: state.bankingAccountOwner
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onClearAccountOwner: () => {
      dispatch(clearAccountOwner());
    },
    selectAccountOwner: (owner) => {
      dispatch(selectAccountOwner(owner));
    }
  };
};


class SelectOwner extends Component {
  componentWillReceiveProps(nextProps) {
    if (isEmpty(nextProps.bankingAccount)) {
      if (nextProps.bankingAccountsReceived !== null && !nextProps.bankingAccountsReceived) {
        this.props.history['replace'](`/usis/go-tuition-gift/${this.props.user.brand}/no-eligible-accounts/${this.props.user.giftAccountGUID}`);
      }
    }
  }
  /*componentWillMount() {
    if (isEmpty(this.props.bankingAccount)) {
      this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/select-account/${this.props.user.giftAccountGUID}`);
    }
    this.props.onClearAccountOwner();
  }*/

  onChange(event) {
    const input = get(event, 'target', {});
    const selectedOwner = get(input, 'value', '');
    this.props.selectAccountOwner(selectedOwner);
  }

  onClick() {
    this.props.history['push'](`/usis/go-tuition-gift/${this.props.user.brand}/amount/${this.props.user.giftAccountGUID}`);
  }

  render() {
    return (
      isEmpty(get(this.props.bankingAccounts, 'accounts')) ?
        ( <div className="text-center select-account-loading gifting-page-layout"><GiftingSpinner /></div> ) :
        ( <div className="text-center select-owner gifting-page-layout">
              <div className="row">
                <div className="col-md-8 col-xs-8 col-xxs-10 col-md-offset-2 col-xs-offset-2 col-xxs-offset-1">
                  <h2 className="select-owner__title"><Translate value="gifter.selectOwner.title" dangerousHTML/></h2>
                </div>
              </div>

             <div className="v-space-40"/>
             <div className="row">
               <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
                 {isEmpty(get(this.props.bankingAccount, 'ownerNames', [])) ?
                 <InputComponent
                   type="text"
                   name="ownerName"
                    placeholder={I18n.t('gifter.selectOwner.inputPlaceholderText')}
                   handleChange={this.onChange.bind(this)}
                   guidanceText={I18n.t('gifter.selectOwner.inputInstructions')}
                 />:
                 <div className="owners-container">
                   {get(this.props.bankingAccount, 'ownerNames', []).map((owner, index) =>
                     (<RadioButton key={'radio-key-'+index}
                                   name="select-owner"
                                   id={'radio-'+index}
                                   value={owner}
                                   label={owner}
                                   isChecked={isEqual(owner,this.props.bankingAccountOwner)}
                                   onChange={this.onChange.bind(this)}/>)
                   )}
                 </div>
                 }
                </div>
              </div>

              <div className="v-space-20"/>

              <div className="row">
                <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
                  <button
                    className="btn btn-default btn-block"
                    onClick={this.onClick.bind(this)}
                    disabled={isEmpty(this.props.bankingAccountOwner)}
                  >
                    <Translate value="gifter.selectOwner.button"/>
                  </button>
                </div>
              </div>

              <div className="v-space-40"/>


              <div className="row">
                <div className="col-md-6 col-xs-10 col-xxs-12 col-md-offset-3 col-xs-offset-1 col-xxs-offset-0">
                  <div className="select-owner__agreement">
                    <Translate value="gifter.selectOwner.ownerAgreement" dangerousHTML/>
                  </div>
                </div>
              </div>
    </div>));
  }
}

SelectOwner.propTypes = {
  user: PropTypes.object,
  bankingAccount: PropTypes.object,
  bankingAccountOwner: PropTypes.string,
  onClearAccountOwner: PropTypes.func,
  selectAccountOwner: PropTypes.func,
  redirect: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectOwner);
export {SelectOwner as Pure};
