import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Translate} from 'react-redux-i18n';
import {connect} from 'react-redux';
// import {BeneficiaryProfilePicture} from 'trp-components';
import shared from '@trp-md529-gifting/trpgifting529';
import './no-eligible-accounts.scss';
const { atoms: { BeneficiaryProfilePicture } }  = shared;

export const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

class noEligibleAccounts extends Component {
  constructor() {
    super();
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history['replace'](`/usis/go-tuition-gift/${this.props.user.brand}/${this.props.user.giftAccountGUID}`);
  }

  render() {
    return (<div className="no-eligible-accounts text-center">
      <BeneficiaryProfilePicture firstName={''} pictureSrc={''}/>
      <div className="no-eligible-accounts__illustration"/>
      <div className="v-space-40"/>
      <div className="row">
        <div className="col-md-6 col-xs-8 col-xxs-12 col-md-offset-3 col-xs-offset-2 col-xxs-offset-0">
          <h2><Translate value="gifter.noEligibleAccounts.title"/></h2>
          <div className="v-space-20"/>
          <p><Translate value="gifter.noEligibleAccounts.message"/></p>
          <div className="v-space-20"/>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-8 col-xxs-12 col-md-offset-4 col-xs-offset-2 col-xxs-offset-0">
          <button className="btn btn-default btn-block" onClick={() => this.goBack()}>
            <Translate value="gifter.noEligibleAccounts.btnLabel"/>
          </button>
        </div>
      </div>
    </div>);
  }
}

noEligibleAccounts.propTypes = {
  redirect: PropTypes.func,
  user: PropTypes.object
};

export default connect(mapStateToProps,null)(noEligibleAccounts);
export {noEligibleAccounts as Pure};
