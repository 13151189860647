import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
// import {END_POINTS, xhr} from 'trp-components';
// import {END_POINTS, xhr} from '@trp-md529-gifting/trpgifting529';
import shared from '@trp-md529-gifting/trpgifting529';
const { services: { xhr, END_POINTS } } = shared;

export const actions = {
  SAVE_GIFT_SUCCESS: 'SAVE_GIFT_SUCCESS'
};

export function saveGift(model, success, failure) {
  return xhr.post(END_POINTS.API_SECURE_BENEFICIARY_GIFT_ACCOUNT_GIFT, null, convertToPayload(model))
    .then((response) => {
      if (response.status === 200) {
        if (success) {
          success();
        }
      } else {
        if (failure) {
          failure();
        }
      }
    })
    .catch(() => {
      if (failure) {
        failure();
      }
    });

}

function convertToPayload(model) {
  let payload = cloneDeep(model);
  payload.gifterProfile.name = payload.gifterProfile.firstName;
  delete payload.gifterProfile.firstName;
  delete payload.gifterProfile.lastName;
  set(payload, 'gifterProfile.emailAddress',
    isEmpty(get(payload, 'gifterProfile.emailAddress')) ?
      null :
      get(payload, 'gifterProfile.emailAddress'));

  const name = isEmpty( get( payload, 'gifterProfile.name') ) ?
    get( payload, 'bankAccount.ownerName') :
    get( payload, 'gifterProfile.name');
  set(payload, 'gifterProfile.name', name);

  // These fields are currently unsupported so setting to null at the adapter
  payload.note = null;
  return payload;
}
