import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { Actions } from '../../actions/banking/authentication.action';

export default function authenticationReducer(state = {}, action = {}) {
  let newState = state;
  switch (action.type) {
    case Actions.ADD_BANKING_AUTHENTICATION:
      newState = cloneDeep(state);
      set(newState, 'authenticationId', action.payload);
      break;
    case Actions.CLEAR_BANKING_AUTHENTICATION:
      newState = {};
      break;
    default:
      break;
  }
  return newState;
}
